

export default {
  name: 'MenuFooter',
  computed: {
    cssProps() {
      return {
        '--bg-color': this.bgColors,
        '--txt-color': this.txtColors
      }
    }
  },
  props: {
    content: {
      type: Object,
      default() {}
    },
    about_us: {
      type: Array,
      default() {}
    },
    help: {
      type: Array,
      default() {}
    },
    bgColors: {
      type: String,
      default: '#FFFFFF'
    },
    txtColors: {
      type: String,
      default: '#000000'
    }
  },
  components: {
    Wrapper: () => import('@/components/Structure/Wrapper'),
    ModalGlobal: () => import('@/components/globals/ModalGlobal'),
    VFooterForm: () => import('@/components/Footer/VFooter-form')
  },
  data: () => ({
    openModal: false
  }),
  mounted() {
    setTimeout(() => {
      this.setImagesDimensions()
          }, 7000)
  },
  methods: {
    closeModal() {
      this.openModal = false
    },
    toggleMenu(event) {
      const menu = event.target.parentNode
      menu.classList.toggle('isOpen')
    },
    setImagesDimensions() {
      const images = document.querySelectorAll('.reclame-aqui img');
      images.forEach(img => {
        img.width = 40
        img.height = 40
        img.alt = `Reclame-aqui ${img.alt}`
      })
    }
  }
}
